import React, {useState} from 'react';

import {useIntl, MessageDescriptor, defineMessage} from 'react-intl';
import styled from 'styled-components';

import {
  ActionButton,
  dimensions,
  colors,
  focusBoxShadow,
} from '@stryd/react-ui';
import {isValidEmail} from '@stryd/util-lib';

import {useApi} from 'src/contexts/ApiContext';
import {signupForNewsletter} from 'src/utils/analytics/events';
import {track} from 'src/utils/analytics/gtag';

const Form = styled.form`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: ${dimensions.breakpoints.tabletPortrait}) {
    flex-direction: column;
  }
`;

const EmailInput = styled.input`
  min-width: 275px;
  padding: 0.5em 1em;
  height: 50px;
  border-radius: 10px;
  border: solid 1px #979797;
  outline: none;
  margin-right: 1em;
  &:focus {
    ${focusBoxShadow as any}
  }

  @media screen and (max-width: ${dimensions.breakpoints.tabletPortrait}) {
    margin-right: 0;
    margin-bottom: 1em;
  }

  @media screen and (max-width: ${dimensions.breakpoints.mobileSmall}) {
    min-width: 80vw;
  }
`;

const ErrorMessageContainer = styled.div`
  position: relative;
  margin: 0.5rem;
  border-radius: 4px;
  min-height: 35px;
`;

const ErrorMessage = styled.div`
  padding: ${dimensions.halfPadding} ${dimensions.defaultPadding};
  font-size: 0.8rem;
  background-color: ${colors.themes.dark.errorAlertBackgroundColor};
  border: 1px solid ${colors.themes.dark.errorAlertBorderColor};
  min-height: 35px;
`;

interface EmailSignupFormProps {
  listName: string;
  tags: string[] | undefined;
}

const emptyEmailMessageDescriptor: MessageDescriptor = defineMessage({
  id: 'errors.requiredEmail',
  defaultMessage: 'This is required. Enter a valid email address.',
});

const invalidEmailMessageDescriptor: MessageDescriptor = defineMessage({
  id: 'errors.invalidEmail',
  defaultMessage: "It looks like this isn't a valid email address.",
});

const validateEmail = (email: string): {err: MessageDescriptor | null} => {
  if (!email) {
    return {err: emptyEmailMessageDescriptor};
  }

  if (!isValidEmail(email)) {
    return {err: invalidEmailMessageDescriptor};
  }

  return {err: null};
};

const sendNewsletterAnalytics = (listName: string) => {
  return track(
    signupForNewsletter({
      listName: listName,
    })
  );
};

const EmailSignupForm: React.FC<EmailSignupFormProps> = (props) => {
  const {listName, tags} = props;
  const intl = useIntl();
  const api = useApi();
  const [inputState, setInputState] = useState<string | null>();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    const newState = e.target.value;
    setInputState(newState);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!submitted && !e.target.value) {
      return;
    }

    const {err} = validateEmail(e.target.value);

    err && setError(intl.formatMessage(err));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputState) {
      return;
    }

    const result = await api.email.signupForList({
      list: listName,
      email: inputState,
      tags: tags,
    });

    if (!result.ok) {
      const response = result.err.response;

      if (response && response.data.includes('Member Exists')) {
        setError(
          intl.formatMessage({
            id: 'NewsletterSignUp.memberExistsText',
            defaultMessage:
              'It looks like you are already signed up for this list.',
          })
        );
        return;
      }
      setError(
        intl.formatMessage({
          id: 'NewsletterSignUp.failText',
          defaultMessage: 'We’re sorry! Sign up failed.',
        })
      );

      return;
    }

    sendNewsletterAnalytics(listName);
    setSubmitted(true);
  };

  return (
    <>
      {!submitted && (
        <Form onSubmit={handleSubmit}>
          <EmailInput
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={intl.formatMessage({
              id: 'NewsletterSignUp.inputPlaceholder',
              defaultMessage: 'Enter your email',
            })}
          />
          <ActionButton type="submit">
            {intl.formatMessage({
              id: 'NewsletterSignUp.defaultButtonText',
              defaultMessage: 'Sign Up',
            })}
          </ActionButton>
        </Form>
      )}
      {submitted && (
        <h3>
          {intl.formatMessage({
            id: 'NewsletterSignUp.successText',
            defaultMessage: 'Thank you for subscribing!',
          })}
        </h3>
      )}
      {error && (
        <ErrorMessageContainer>
          <ErrorMessage role="alert">{error}</ErrorMessage>
        </ErrorMessageContainer>
      )}
    </>
  );
};

export default EmailSignupForm;
